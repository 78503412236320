import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import ReactPaginate from "react-paginate";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  ul {
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 5px;
    list-style-type: none; /* li 태그의 기본 목록 스타일을 제거 */
  }

  a {
    padding: 5px 10px;
    border: 1px solid #2C3892; //#007bff;
    color: #2C3892;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: #2C3892;
      color: white;
    }
  }

  .active a {
    background-color: #2C3892;
    color: white;
  }
`;

const Section = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  // height: 40vh;
`;

const Title = styled.h2`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
`;

const Divider = styled.hr`
  border: 1px solid #CCCCCC;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  th, td {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
  }
  th {
    text-align: left;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const BookingList = ({setSelectedDay}) => {
  const { currentUser } = useAuth();
  const [bookings, setBookings] = useState([]);
  const [error, setError] = useState("");
  const [activePage, setActivePage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        // console.log("currentUser is", currentUser);
        const response = await axios.post(`https://${process.env.REACT_APP_API_DNS}/api/admin/bookings-date/${currentUser.adminId}`, {
          refreshToken: currentUser.refreshToken,
          accessToken: currentUser.accessToken
        });

        if (response.status === 200) {
          setBookings(response.data);
        } else {
          setError("Failed to fetch bookings");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Unmatched user Id and token");
        } else if (error.response && error.response.status === 404) {
          setError("아직 예약 내역이 존재하지 않습니다.");
        } else {
          setError("Error Detected");
        }
      }
    };

    if (currentUser) {
      fetchBookings();
    }
  }, [currentUser]);

  const getDays = () => {
    let today = new Date();
    let days = [];
    for (let i = 0; i < 365; i++) { // 1년
      const day = new Date(today);
      // day.setMonth(today.getMonth() - 2); // 2개월 전 ~ 
      // day.setDate(today.getDate() + i);
      day.setDate(today.getDate() - 7 + i); // 1주일 전 ~
      days.push(day.toISOString().split('T')[0]);
    }
    return days;
  };

  let Days = getDays();

  const handleRowClick = (day) => {
    setSelectedDay(day); // 클릭한 예약을 전달
  };

  // 페이지네이션을 위한 예약 리스트 분리
  const indexOfLastBooking = (activePage + 1) * itemsPerPage; // 한 페이지에 표시할 마지막 일자
  const indexOfFirstBooking = indexOfLastBooking - itemsPerPage; // 한 페이지에 표시할 첫 일자
  const paginatedBookings = Days.slice(indexOfFirstBooking, indexOfLastBooking); // 현재 페이지에 맞는 일자들 분리

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber.selected);
  };

  return (
    <Section>
      <Title>예약 내역</Title>
      <Divider />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Table>
        <thead>
          <tr>
            <th>예약접수일자</th>
            <th>예약수</th>
            <th>예약인원</th>
            <th>매출액</th>
          </tr>
        </thead>
        <tbody>
          {paginatedBookings.map((day, index) => {
            const dailyBookings = bookings.filter(booking => new Date(booking.selectedDay).toISOString().split('T')[0] === day);
            const totalQuantity = dailyBookings.reduce((sum, booking) => sum + parseInt(booking.totalQuantity, 10), 0);
            const totalPrice = dailyBookings.reduce((sum, booking) => sum + parseInt(booking.finalPrice, 10), 0);

            return (
              <tr
                key={index}
                onClick={() => handleRowClick(day)} 
                style={{ cursor: 'pointer' }}
              >
                <td>{day}</td>
                <td>{dailyBookings.length}</td>
                <td>{totalQuantity}</td>
                <td>{totalPrice.toLocaleString()}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* 페이지네이션 컴포넌트 */}
      <PaginationContainer>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={Math.ceil(Days.length / itemsPerPage)}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          activeClassName={"active"}
        />
      </PaginationContainer>
    </Section>
  );
};


export default BookingList;
