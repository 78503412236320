import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";

const Section = styled.div`
  // display: none;
  display: ${({ show }) => (show ? 'block' : 'none')}; // 날짜 선택되면 block
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  height: 40vh;
  overflow-y: scroll;
`;

const Title = styled.h2`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
`;

const Divider = styled.hr`
  border: 1px solid #CCCCCC;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  th, td {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
  }
  th {
    text-align: left;
  }
`;

const TableCell = styled.td`
  vertical-align: middle;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const BookingDetails = ({selectedDay}) => {
  const { currentUser } = useAuth();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [error, setError] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await axios.post(`https://${process.env.REACT_APP_API_DNS}/api/admin/bookings-detail/${currentUser.adminId}`, {
          refreshToken: currentUser.refreshToken,
          accessToken: currentUser.accessToken
        });

        if (response.status === 200) {
          setBookingDetails(response.data);
          // console.log("response data is", response.data);
        } else {
          setError("Failed to fetch booking details");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Unmatched user Id and token");
        } else if (error.response && error.response.status === 404) {
          setError("아직 예약 내역이 존재하지 않습니다.");
        } else {
          setError("Error Detected");
        }
      }
    };

    if (currentUser) {
      fetchBookingDetails();
    }
  }, [currentUser]);


  useEffect(() => {
    if(selectedDay){
      const filtered = bookingDetails.filter(
        (booking) => booking.selectedDay.split('T')[0] === selectedDay
      );
      setFilteredBookings(filtered);
    }
  }, [selectedDay, bookingDetails]);
  
  if (!selectedDay) {
    return null; // 선택된 예약이 없으면 아무것도 렌더링하지 않음
  }

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  const handleRefund = async (paymentId) => {
    try {
      // console.log("paymentKey is", paymentId);
      setLoading(true);
      const response = await axios.post(`https://${process.env.REACT_APP_API_DNS}/api/payment/cancellation`, {
        paymentId: paymentId
      });

      if (response.status === 200) {
        alert("환불이 성공적으로 처리되었습니다.");
        // Optionally refresh booking details here after refund
      } else {
        alert("환불 처리에 실패했습니다.");
      }
    } catch (error) {
      console.error("Error during refund:", error);
      alert("환불 중 오류가 발생했습니다.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Section show={selectedDay}>
      <Title>{`${selectedDay} 접수된 예약 내용 상세`}</Title>
      <Divider />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Table>
        <thead>
          <tr>
            <th>예약자명</th>
            <th>전화번호</th>
            <th>아이템 종류</th>
            <th>예약 수량</th>
            <th>지불금액</th>
            <th>예약 종류</th>
            <th>예약 일시</th>
            <th>이용 일시</th>
            <th>paymentId</th>
          </tr>
        </thead>
        <tbody>
          {filteredBookings.length === 0 ? 
            (
            <tr>
              <td colSpan="8">선택된 날짜에 예약 내역이 없습니다.</td>
            </tr>
            ) : (
              filteredBookings.map((booking, index) => (
                <>
                  <tr
                    key={index}
                    onClick={() => handleRowClick(index)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: selectedRow === index ? '#f0f8ff' : 'transparent', // 선택된 tr에 음영
                    }}
                  >
                    <TableCell>{booking.userName}</TableCell>
                    <TableCell>{booking.phoneNumber}</TableCell>
                    <TableCell>{booking.itemId}</TableCell>
                    <TableCell>{booking.totalQuantity.toLocaleString()}</TableCell>
                    <TableCell>{booking.finalPrice.toLocaleString()}</TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {Object.entries(booking.selectedOptions).map(([key, value]) =>
                          value > 0 ? <span key={key}>{`${key} x ${value}`}</span> : 'none'
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{booking.timestamp.toLocaleString()}</TableCell>
                    <TableCell>{booking.selectedDay}</TableCell>
                    <TableCell>{booking.paymentId}</TableCell>
                  </tr>
                  {selectedRow === index && (
                    <tr>
                      <TableCell colSpan={8}>
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: '10px 0' }}>
                          <button onClick={() => alert('예약완료')}>예약완료</button>
                          <button onClick={() => alert('이용취소')}>이용취소</button>
                          <button onClick={() => alert('이용완료')}>이용완료</button>
                          <button onClick={() => handleRefund(booking.paymentId)} disabled={loading}>
                            {loading ? '환불 처리 중...' : '환불하기'}
                          </button>
                        </div>
                      </TableCell>
                    </tr>
                  )}
                </>
              ))
            )}
        </tbody>
      </Table>
    </Section>
  );
};

export default BookingDetails;
