import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const SidebarContainer = styled.div`
  // width: 250px;
  background-color: #2C3892;
  color: #F4F4F4;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100vh;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 60px;
  margin-top: 40px;
  padding: 10px;
`;

const BusinessName = styled.h3`
  font-size: 22px;
  margin: 10px 0 20px;
  text-align: center;
  margin-bottom: 100px;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const MenuItem = styled.li`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: +0.05em;
  cursor: pointer;
  &:hover {
    color: #B0B0B0; /* Light grey color on hover */
  }
`;

const LinkItem = styled(MenuItem)`
  text-decoration: none;
  color: #F4F4F4;
  &:hover {
    color: #B0B0B0;
  }
`;

const Footer = styled.footer`
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
`;

const Sidebar = () => {
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <SidebarContainer>
      <div>
        <Title>Splash</Title>
        <BusinessName>{currentUser.centerName}</BusinessName>
        <MenuList>
          <LinkItem as="a" href="https://www.splashreservation.com">예약페이지 바로가기</LinkItem>
          <MenuItem onClick={() => navigate("/admin")}>예약 목록</MenuItem>
          <MenuItem onClick={() => navigate("/change-password")}>비밀번호 변경</MenuItem>
          <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
        </MenuList>
      </div>
      <Footer>
        <p>Copyright 2024 Splash</p>
      </Footer>
    </SidebarContainer>
  );
};

export default Sidebar;
